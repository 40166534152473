import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Logo, Link } from '@components'
import { withStyles } from '@material-ui/core/styles'
import { paths } from 'routes'
import { withTranslation, useTranslation } from 'react-i18next'

function Copyright() {
	const { t } = useTranslation()
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{t('forgotPassword.copyright')}
			<Link variant="body2" href={'https://www.bigdieta.com'}>
				{t('forgotPassword.dieta_health')}
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	)
}

const styles = (theme) => ({
	root: {
		height: '100vh',
	},
	header: {
		padding: 20,
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	iconWrapper: {
		margin: 'auto',
		lineHeight: 1,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	actionsContainer: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	boldLink: {
		fontWeight: 'bold',
	},
})

const auth0ErrorMapper = (errorPayload) => {
	const error = errorPayload && errorPayload.error
	let errorMessage = 'Authentication Failed!'
	if (error) {
		errorMessage = error.description
	}
	if (errorMessage === 'Global limit has been reached') {
		window.alert(
			'User Registration temporarily disabled',
			"We're experiencing a large amount of traffic and have put access enforcements in place at this time.  Sorry about that. Try again later"
		)
	}
	if (error && error.name) {
		if (error.code === 'Missing required parameters') {
			errorMessage = 'Invalid credentials'
		}
		if (error.code === 'a0.response.invalid') {
			errorMessage = 'Invalid credentials'
		}
		if (error.code === 'too_many_attempts') {
			errorMessage = error.description
		}
	}
	return errorMessage
}

class ForgotPassword extends Component {
	constructor(props) {
		super(props)
		this.emailRef = React.createRef()
	}

	state = {
		authError: null,
		loading: false,
	}

	static propTypes = {
		auth: PropTypes.shape({
			accessToken: PropTypes.string,
		}).isRequired,
		resetForgotPassword: PropTypes.func.isRequired,
	}

	static defaultProps = {
		auth: {
			accessToken: null,
		},
	}

	onPressResetPassword = async (e) => {
		console.log('onPressResetPassword')
		e.preventDefault()
		this.setState({
			loading: true,
		})
		const res = await this.props.resetForgotPassword(this.emailRef.value)
		if (res.err) {
			this.setState({
				errorMessage: 'Unable to reset password.',
			})
		} else {
			this.setState({
				emailSent: true,
			})
		}
		this.setState({
			loading: false,
		})
	}

	render() {
		const { t, classes } = this.props
		return (
			<Grid justifyContent="center" container component="main" className={classes.root}>
				<CssBaseline />
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<div className={classes.paper}>
						<Grid container alignItems="center" spacing={2} direction="row">
							<Grid item className={classes.iconWrapper}>
								<Logo scale={1} />
							</Grid>
						</Grid>
						<Typography component="h1" variant="h5" className={classes.header}>
							{t('forgotPassword.title')}
						</Typography>
						{this.state.emailSent ? (
							<>
								<Typography component="h2" variant="subtitle1" gutterBottom>
									{t('forgotPassword.email_sent')}
								</Typography>
								<Typography component="h3" variant="subtitle2" classNam>
									{t('forgotPassword.email_sent_disclaimer')}
								</Typography>
							</>
						) : (
							<form
								className={classes.form}
								noValidate
								onSubmit={(e) => {
									this.onPressResetPassword(e)
								}}
							>
								{this.state.errorMessage ? (
									<Typography component="h2" variant="subtitle1">
										{t('forgotPassword.email_sent_error')}
									</Typography>
								) : (
									<></>
								)}
								<TextField
									inputRef={(ref) => (this.emailRef = ref)}
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="email"
									label={t('forgotPassword.email')}
									name="email"
									autoComplete="email"
									autoFocus
								/>
								<Grid container spacing={3} className={classes.actionsContainer}>
									<Grid item xs={12}>
										<Button type="submit" fullWidth variant="contained">
											{t('forgotPassword.reset_password')}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							className={classes.actionsContainer}
						>
							<Grid item xs>
								<Link to={paths.login} variant="body2" className={classes.boldLink}>
									{t('forgotPassword.login_already_have')}
								</Link>
							</Grid>
							<Grid item>
								<Link to={paths.signup} variant="body2" className={classes.boldLink}>
									{t('forgotPassword.signup')}
								</Link>
							</Grid>
						</Grid>
						<Box mt={5}>
							<Copyright />
						</Box>
					</div>
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation(), withStyles(styles))(ForgotPassword)
